import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Cookies, withCookies } from "react-cookie";

import FacilitiesApiClient from "@/api/client/entities/facilities";
import { baseEnvironmentConfig } from "@/api/constants";
import ClubPageHero from "@/components/ClubPageHero";
import DynamicRenderer from "@/components/DynamicRenderer";
import Layout from "@/components/Layout";
import {
  PAGE_CATEGORY_ENUM,
  getClubStatus,
} from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer";
import { ACDL_GLOBAL_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/global-tracking";
import { CLUB_STATUSES, CONTENT_TYPES, LOCAL_STORAGE_KEYS } from "@/constants";
import BrazeContext from "@/context/BrazeContext";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { UserContext } from "@/context/User";
import useWebView from "@/hooks/useWebView";
import { getCmsEntries } from "@/server/lib/contentful";
import { pushToHash } from "@/utils/helpers/extract-section-id";
import { getModuleList } from "@/utils/helpers/module_list";

const ClubDetailPage = ({ club, clubName, facility, region }) => {
  const brazeContext = useContext(BrazeContext);
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);
  const userContext = useContext(UserContext);
  const { asPath } = useRouter();
  const [acdlPageLoadFlag, setAcdlPageLoadFlag] = useState(false);
  const isWebView = useWebView();

  const { navigation, notificationBanner, hero, modules, clubData } =
    club.fields;
  const metaData = club.fields.metaData && club.fields.metaData.fields;
  const clubStatus = getClubStatus(facility.status);
  const { getDataLayerInfo } = userContext;

  const scrollFunc = (window, asPath) => {
    if (window) {
      if (window.location.hash) {
        pushToHash(window.location.hash);
      }
    } else {
      pushToHash(asPath);
    }
  };

  useEffect(() => {
    scrollFunc(window, asPath);

    if (window) {
      window.addEventListener("hashchange", () => {
        scrollFunc(window, asPath);
      });
    }

    if (window) {
      return () => {
        window.removeEventListener("hashchange", scrollFunc);
      };
    }
  }, [asPath]);

  useEffect(() => {
    if (clubData && facility) {
      const timer = setTimeout(() => {
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.CLUB_PAGE_VISITED,
          JSON.stringify({
            clubName: clubData.fields.webName,
            facilityId: facility.facilityId,
          })
        );
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [clubData, facility]);

  useEffect(() => {
    //Triggers braze In app message Campaign based on the custom event
    if (!isWebView && brazeContext.isInitialized) {
      const braze = brazeContext.instance;

      braze.logCustomEvent("CLUB_DETAIL_IN_APP_MESSAGE", {
        club: clubName,
      });
    }
  }, [brazeContext.instance, brazeContext.isInitialized, clubName, isWebView]);

  useEffect(() => {
    if (userContext?.initialized && !acdlPageLoadFlag) {
      setAcdlPageLoadFlag(true);

      const ACDLInfo = {
        ...ACDL_GLOBAL_TRACKING.programmaticUrlRoute(
          asPath,
          metaData.title.toLowerCase(),
          clubStatus == CLUB_STATUSES.PRESALE
            ? PAGE_CATEGORY_ENUM.PRE_SALE
            : PAGE_CATEGORY_ENUM.HIGH_INTEREST
        ),
        club: {
          ...(facility.facilityId && { facilityID: facility.facilityId }),
          ...(facility.clubId && { clubID: facility.clubId.toString() }),
          ...(region && { clubRegion: region.toLowerCase() }),
          ...{ clubName: clubData.fields.webName.toLowerCase() },
          ...(clubStatus && { clubStatus: clubStatus.toLowerCase() }),
          ...(facility.clubType && {
            clubType: facility.clubType.toLowerCase(),
          }),
        },
        user: getDataLayerInfo(),
      };

      setNewDataLayer(ACDLInfo);
    }
  }, [
    acdlPageLoadFlag,
    clubData,
    clubStatus,
    facility.clubId,
    facility.clubType,
    facility.facilityId,
    getDataLayerInfo,
    metaData.title,
    region,
    asPath,
    setNewDataLayer,
    userContext,
  ]);

  const handleClassScheduleAcdlEvent = () => {
    const classSchedule = " | class schedule";
    const ACDLEvent = {
      ...ACDL_GLOBAL_TRACKING.programmaticUrlRoute(
        asPath,
        `${metaData.title.toLowerCase()}${classSchedule}`,
        clubStatus == CLUB_STATUSES.PRESALE
          ? PAGE_CATEGORY_ENUM.PRE_SALE
          : PAGE_CATEGORY_ENUM.HIGH_INTEREST,
        classSchedule
      ),
      user: getDataLayerInfo(),
    };
    setNewDataLayer(ACDLEvent);
  };

  const moduleList = getModuleList(modules);

  return (
    <Layout
      club={club}
      dataLayer={{}}
      facilityData={facility}
      metaData={metaData}
      modules={modules}
      navigation={navigation}
      notificationBanner={notificationBanner}
      page={baseEnvironmentConfig.pageNames.clubDetail}
    >
      {hero && (
        <ClubPageHero
          club={club}
          data={hero.fields}
          facility={facility}
          isClubDetail={true}
          moduleList={moduleList}
        />
      )}

      {modules.map((module, index) => (
        <DynamicRenderer
          {...module?.fields}
          club={club}
          data={module?.fields}
          facility={facility}
          fileName={module?.sys?.contentType?.sys?.id}
          handleClassScheduleAcdlEvent={handleClassScheduleAcdlEvent}
          id={module?.sys?.id}
          isClubDetail={true}
          key={`${module?.sys?.id}-${index + 1}`}
          moduleList={moduleList}
        />
      ))}
    </Layout>
  );
};

ClubDetailPage.propTypes = {
  club: PropTypes.exact({
    fields: PropTypes.object.isRequired,
    metadata: PropTypes.object,
    sys: PropTypes.object.isRequired,
  }).isRequired,
  clubName: PropTypes.string,
  clubs: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies),
  facility: PropTypes.object.isRequired,
  query: PropTypes.object,
  region: PropTypes.string,
};

export default withCookies(ClubDetailPage);

/**
 * @param { context }
 * context.res, context.req, context.query
 *
 * /clubs/new-york/midtown/park-ave
 */
export const getServerSideProps = async ({ query }) => {
  const { clubName = null, region, subregion } = query;

  const slug = `/clubs/${region}${subregion ? `/${subregion}` : ""}${
    clubName ? `/${clubName}` : ""
  }`.toLowerCase();

  const contentType = CONTENT_TYPES.PAGE_CLUB_DETAIL;
  const filters = {
    "fields.clubData.fields.clubDetailPageURL": slug,
    "fields.clubData.sys.contentType.sys.id": "club",
  };
  let club;
  let facilityId;
  let facility;

  try {
    club = await getCmsEntries({ contentType, filters, include: 10 });

    const otherLocations = {};

    club?.fields?.modules?.some((module) => {
      if (module?.sys?.contentType?.sys?.id === "moduleOtherLocations") {
        module?.fields?.clubs?.forEach((club) => {
          otherLocations[club?.fields?.facilityId] = club;
        });

        return true;
      }
    });

    if (Object.keys(otherLocations).length > 0) {
      const apiFacilitiesData = await FacilitiesApiClient.getFacilities(
        Object.keys(otherLocations)
      );

      apiFacilitiesData?.forEach((facilityData) => {
        otherLocations[facilityData.id].fields = {
          ...otherLocations[facilityData.id].fields,
          featuredAmenities: facilityData.featuredAmenities,
          region: facilityData.region,
          status: facilityData.status,
          type: facilityData.type,
        };
      });
    }

    facilityId = club?.fields?.clubData?.fields?.facilityId || 0;

    facility = club.fields && (await FacilitiesApiClient.findById(facilityId));
  } catch (error) {
    console.error(`No data found for facilityId ${facilityId}.`, error);
  }

  if (!club || !club.fields || !facility) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      club,
      clubName,
      facility,
      region,
      slug: slug.substring(1),
    },
  };
};
